import { render, staticRenderFns } from "./completa-tu-perfil.vue?vue&type=template&id=01543958"
import script from "./completa-tu-perfil.vue?vue&type=script&lang=js"
export * from "./completa-tu-perfil.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports